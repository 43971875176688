import './App.css';

import background from  './images/background-1.png'
import iconArrowDown from './images/icons/icon-arrow-down-white.svg'
import invHubLogo from './images/inv-hub-logo.svg'
import investing from  './images/investing.svg'
import hub from  './images/hub.svg'
import dropDown from './images/icons/dropdown-menu.svg'
import imageBanner from './images/banner-image.svg'
import mdiFinance from './images/icons/mdi-finance.svg'
import platformImage from './images/plataforma.png'
import platformCompleteImage from './images/plataformaCompleta.png'
import linkedin from './images/icons/linkedin.svg'

import axios from "axios";
import React from 'react';
import { useForm } from "react-hook-form";

const baseURL = 'https://teuf5oogsc.execute-api.us-east-2.amazonaws.com/sendmail'
const headers = {
    "Content-Type": "application/json",
    "x-api-key": "7GsDlbwz4u2OLBdagUs5H5wJ3aw0VMOF5tOxfnWi",
}
function App() {
    const { register, handleSubmit } = useForm();

    const handleRegistration = (params) => {
        axios.post(baseURL, params, {headers}).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <>
            <section id='home-screen'>
                <img className="demo-bg card-img" src={background} alt=""/>
                <div className="banner card-img-overlay">

                    <div className="container h-100">
                        <nav className="navbar">
                            <div className="navbar-images">
                                <img src={invHubLogo} alt="Logo" className="logo-inv-hub"/>
                                <img src={investing} alt="Logo investing" className="logo-investing d-none d-sm-inline"/>
                                <img src={hub} alt="Logo hub" className="logo-hub d-none d-sm-inline"/>
                            </div>
                            <div className="d-none d-lg-flex col justify-content-end">
                                <ul className="nav">
                                    <li className="nav-item"><a className="nav-link" href="#home-screen">INÍCIO</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#platform">A PLATAFORMA</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#about">SOBRE</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#clients">CLIENTES</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#contact">SOLICITE UM ORÇAMENTO</a></li>
                                </ul>
                            </div>
                            <div className="d-lg-none dropstart">
                                <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className="icon-menu" src={dropDown} alt="Menu"/>
                                </button>
                                <ul className="dropdown-menu">
                                    <li className="nav-item"><a className="nav-link" href="#home-screen">INÍCIO</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#platform">A PLATAFORMA</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#about">SOBRE</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#clients">CLIENTES</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#contact">SOLICITE UM ORÇAMENTO</a></li>
                                </ul>
                            </div>
                        </nav>

                        <div className="banner-title">
                            <div className="container p-0">
                                <div className="d-flex flex-column flex-md-row align-items-center">
                                    <div className="col col-md-7 mb-5">
                                        <h1 className="me-2">
                                            Sistema operacional de uma gestora de recursos completo, seguro e simples de utilizar.
                                        </h1>
                                    </div>
                                    <div className="col">
                                        <img src={imageBanner} alt="Banner" className="img-fluid img-banner"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <a href="#platform" className="scrollnextsection">
                            <img src={iconArrowDown} alt="Scroll to next section" />
                        </a>
                    </div>
                </div>
            </section>
            
            <section id='platform'>
                <div className="container p-0">
                    <div className="d-flex flex-column flex-md-row h-100 align-items-center mx-2 mx-sm-0">
                        <div className="col text-center text-md-start">
                            <h2 className="py-4">A Plataforma</h2>

                            <div className="vstack gap-3">

                                {/* <div className="mb-3 h-100">
                                    <div className="row g-0 align-items-center h-100">
                                        <div className="card col-1 background-mdi-finance">
                                            <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-1 ">
                                        </div>
                                        <div className="col-10">
                                            <div className="card-body">
                                                <h5 className="card-title">Gestão da carteira</h5>
                                                <p className="card-text">
                                                    Permite que a gestora acompanhe a posição atual e historica de cada fundo ou carteira administrada.
                                                    Com a quantidade, PU, %PL, attribution diária de cada ativo, podendo agrupar por subclasse de ativo, 
                                                    Visualize a performance do seu fundo, de cada ativo, ou
                                                    subclasse de ativo; de suas estratégias isoladamente; automatize os
                                                    processos de backoffice integrando boletagem e comunicação com sua
                                                    corretora; verificando a carteira do seu administrador de forma automática e
                                                    corrigindo os desvios existentes; veja a performance de fundos concorrentes
                                                    e dos principais índices de mercado; tudo em tempo real;
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="mb-3 h-100">
                                    <div className="row g-0 align-items-center h-100">
                                        <div className="card col-1 background-mdi-finance">
                                            <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-1 ">
                                        </div>
                                        <div className="col-10">
                                            <div className="card-body">
                                                <h5 className="card-title">Gestão de Ativos</h5>
                                                <p className="card-text">Visualize a performance do seu fundo, de cada ativo, ou
                                                    subclasse de ativo; de suas estratégias isoladamente; automatize os
                                                    processos de backoffice integrando boletagem e comunicação com sua
                                                    corretora; verificando a carteira do seu administrador de forma automática e
                                                    corrigindo os desvios existentes; veja a performance de fundos concorrentes
                                                    e dos principais índices de mercado; tudo em tempo real;
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 h-100">
                                    <div className="row g-0 align-items-center h-100">
                                        <div className="card col-1 background-mdi-finance">
                                            <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-1 ">
                                        </div>
                                        <div className="col-10">
                                            <div className="card-body">
                                                <h5 className="card-title">Gestão de Passivos
                                                </h5>
                                                <p className="card-text">
                                                    Profissionalize a relação com o seu cotista; permita
                                                    que seu cotista consiga ver a sua rentabilidade em tempo real; economize
                                                    tempo do backoffice com seu cotista realizando depósitos e resgates
                                                    realizados diretamente pela plataforma</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 h-100">
                                    <div className="row g-0 align-items-center h-100">
                                        <div className="card col-1 background-mdi-finance">
                                            <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-1 ">
                                        </div>
                                        <div className="col-10">
                                            <div className="card-body">
                                                <h5 className="card-title">Compliance</h5>
                                                <p className="card-text">
                                                    Seja avisado sobre ações que desenquadre o seu
                                                    fundo; acompanhamento da exposição máxima dos fundos perante os emissores;
                                                    conheça o seu cliente e tenha uma inteligência te apoiando
                                                    para enquadramento amplo da CVM e órgãos reguladores;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3 h-100">
                                    <div className="row g-0 align-items-center h-100">
                                        <div className="card col-1 background-mdi-finance">
                                            <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                        </div>
                                        <div className="col-1 ">
                                        </div>
                                        <div className="col-10">
                                            <div className="card-body">
                                                <h5 className="card-title">Integrações</h5>
                                                <p className="card-text">
                                                    Comunicação rapida, direta e simplificada com as administradores; 
                                                    Aa integrações permitem buscar informações dos fundos/carteiras administradas, 
                                                    aprovar carteiras e enviar boletas diretamente para as administradoras;
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="d-none d-md-flex col-5">
                            <img src={platformImage} alt="platform" className="img-fluid img-platform" />
                        </div>

                    </div>
                </div>

                <div className="d-flex d-md-none col justify-content-end py-3">
                    <img src={platformImage} alt="platform" className="img-fluid img-platform w-75" />
                </div>
            </section>

            <section id='functionalities'>
                <div className="container h-100">
                <div className="row h-100 align-items-center text-center py-5">
                    <div className="col">
                    <h2 className="mb-5">Funcionalidades</h2>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Visão da performance de ativos</h5>
                                            <p className="card-text">Veja por estratégia, setor, emissor e subclasse todos
                                                os ativos de cada um dos seus fundos
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Relatório de rentabilidade</h5>
                                            <p className="card-text">Compare sua rentabilidade com os principais índices de
                                                mercado, escolha também os fundos concorrentes para comparação
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Conciliação Carteira</h5>
                                            <p className="card-text">Verifique se seu administrador enviou a carteira;
                                            faça a conciliação da cota enviada pelo administrador e a calculada pelo sistema
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Boletagem Integrada</h5>
                                            <p className="card-text">Tenha todas as informações de ordens centralizadas; e
                                                nenhum esforço para enviar esta informação
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..."/>
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Histórico</h5>
                                            <p className="card-text">Centralize a informação para tomada de decisão histórica</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Know Your Client</h5>
                                            <p className="card-text">Personalize seu Know Your Client e fique enquadrado na
                                                CVM
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Resgate e Aplicação</h5>
                                            <p className="card-text">Profissionalize a relação com o seu cotista; permita
                                                que ele realize resgate e Aplicação com praticidade; sem tomar tempo do
                                                seu backoffice;
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Conciliação Diária</h5>
                                            <p className="card-text">Conciliação Diária para o seu cotista e para seus
                                                clientes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Cartas ao Gestor</h5>
                                            <p className="card-text">Relacione com o cliente de forma transparente e prática
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Desenquadramento de Fundo</h5>
                                            <p className="card-text">Tenha toda a cobertura das regras das CVM aplicada a
                                                cada operação de cada fundo seu; não corra o risco de ter erros
                                                operacionais que podem resultar em multas;
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Cálculo de Rebate</h5>
                                            <p className="card-text">Não fique refém de erros operacionais e verifique de
                                                forma confiável os rebates do seu distribuidor
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="border mb-3 h-100 rounded">
                                <div className="row g-0 align-items-center h-100 justify-content-evenly p-2">
                                    <div className="col-3 ">
                                        <img src={mdiFinance} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-8">
                                        <div className="card-body">
                                            <h5 className="card-title">Wealth e Fundos</h5>
                                            <p className="card-text">Todas as funcionalidades para o mercado também de
                                                wealth management
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            <section id='about'>

                <div className="container h-100">
                    <div className="d-flex flex-column flex-md-row h-100 align-items-center py-5">
                        <div className="col me-4 text-center text-sm-start">
                            <h2 className="mb-5">Otimize o tempo e aumente a produção da equipe</h2>
                            <p className="mb-5 about-paragraph">
                                Com a Investing Hub, os dados estarão sempre
                                disponíveis para gestão online.
                                Com as informações em um ambiente centralizado,
                                as chances de erros caem drasticamente,
                                aumentando considerávelmente a qualidade da
                                informação.
                            </p>

                            <div className="row">
                                <p className="col about-info text-start">
                                    + 15 fundos <br/><small>sob gestão</small>
                                </p>
                                <p className="col about-info text-start">
                                    + 40 carteiras adm. <br/><small>sob gestão</small>
                                </p>
                                <p className="col about-info text-start">
                                    + 2 bi <br/><small>sob administração</small>
                                </p>
                                {/* <p className="col about-info">
                                    + 10 mil <br/><small>cotistas</small>
                                </p> */}
                            </div>

                        </div>
                        <div className="col col-sm-5">
                            <img src={platformCompleteImage}loading="lazy" alt="platform" className="img-fluid" />
                            {/* <img src="images/plataforma.png" loading="lazy" alt="platform" className="img-fluid" /> */}
                        </div>
                    </div>
                </div>

            </section>

            {/* <section id='clients'>
                <div className="container h-100">
                    <div className="row h-100 align-items-center text-center py-5">
                        <div className="col">
                            <h2 className="mb-5">Clientes</h2>

                            <div className="d-flex flex-row clients-info justify-content-center">
                                <div className="col-6 mb-5 mx-3"> */}
                                    {/* <img src="images/client-img-1.jpeg" className="img-fluid rounded-circle mb-3 client-image" loading="lazy" alt="Client" /> */}
                                    {/* <p>
                                        "Since 2014, we've grown from a regional solution provider into a truly global presence.
                                        Our customers choose us because we blend a highly configurable investment management
                                        platform with a deeply experienced"
                                    </p>
                                    <div className="clients-names">Evaldo Fontes, Araújo Fontes</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

            <section id='contact'>
                <div className="container h-100">
                    <div className="row h-100 align-items-center text-center justify-content-center">
                        <div className="col col-md-8 my-5">
                            <h2 className="mb-5">Solicite um orçamento</h2>

                            <form id="myForm" onSubmit={handleSubmit(handleRegistration)} >
                                <div className="mb-3">
                                    <input required autoComplete="name" type="text" className="form-control" id="nome" placeholder="Nome"  {...register('name')} />
                                </div>

                                <div className="mb-3">
                                    <input type="email" className="form-control" id="email" placeholder="E-mail" {...register('email')}/>
                                </div>

                                <div className="mb-3">
                                    <input required autoComplete="off" type="text" className="form-control" id="celular" placeholder="Celular" {...register('phone')}/>
                                </div>

                                <div className="mb-3">
                                    <input required autoComplete="off" type="text" className="form-control" id="assunto" placeholder="Assunto" {...register('subject')}/>
                                </div>

                                <div className="mb-3">
                                    <textarea required autoComplete="off" className="form-control" id="mensagem" placeholder="Mensagem" rows="3" {...register('msg')}></textarea>
                                </div>

                                <button type="submit" className="btn btn-custom w-100">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>

            </section>  

            <section id='footer'>
                <div className="container h-100 py-5 px-0">
                    <div className="d-flex flex-column flex-md-row h-100 align-items-center justify-content-between">
                        <div className="col mb-3 mb-md-0 text-center text-md-start">
                            <div className="navbar-images mb-3 mb-md-5">
                                <img src="images/inv-hub-logo-mobile.svg" alt="Logo" className="logo-inv-hub" />
                            </div>
                            <div className="company-info">
                                INVESTINGHUB TECNOLOGIA LTDA <br/>
                                CNPJ 47.610.062/0001-89
                            </div>
                        </div>

                        <div className="d-none d-md-flex col mb-3 mb-md-0 justify-content-center navigation-links">
                            <ul className="nav flex-column">
                                <li className="nav-item"><a className="nav-link" href="#home-screen">INÍCIO</a></li>
                                <li className="nav-item"><a className="nav-link" href="#platform">A PLATAFORMA</a></li>
                                <li className="nav-item"><a className="nav-link" href="#about">SOBRE</a></li>
                            </ul>
                        </div>

                        <div className="d-none d-md-flex col mb-3 mb-md-0 justify-content-center navigation-links">
                            <ul className="nav flex-column">
                                <li className="nav-item"><a className="nav-link" href="#functionalities">FUNCIONALIDADES</a></li>
                                <li className="nav-item"><a className="nav-link" href="#clients">CLIENTES</a></li>
                                <li className="nav-item"><a className="nav-link" href="#contact">SOLICITE UM ORÇAMENTO</a></li>
                            </ul>
                        </div>

                        <div>
                            <div className="d-flex justify-content-end align-content-end social-links">
                                <a href="https://www.linkedin.com/company/investing-hub/" rel='noreferrer'>
                                <img src={linkedin} alt="linkedin" className="logo-linkedin" />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
  );
}

export default App;
